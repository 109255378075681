[<template>
  <v-container fluid @keydown.enter.prevent="applyFilters" >
    <h1 class="h1">Acompanhamento de Produção</h1>
    <p></p>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-card outlined v-show="showFilters" dense class="mb-2">
          <h4 class="ml-4 mt-4">Pesquisar por</h4>
          <v-row dense class="py-2 px-4">
            <v-col cols="2">
              <v-autocomplete :loading="loading" item-value="id" item-text="apelido" :items="prestadores" outlined
                label="Profissional" v-model="objeto.profissional" dense clearable />
            </v-col>
            <v-col cols="2">
              <v-autocomplete :loading="loading" item-value="id" item-text="nome" :items="especialidade" outlined label="Especialidade" v-model="objeto.especialidade" dense clearable />

            </v-col>

            <v-col cols="2">
              <v-text-field v-model="objeto.data_inicio" outlined label="Data início " type="date" dense clearable
                :rules="requiredField" :loading="loading" />
            </v-col>
            <v-col cols="2">
              <v-text-field v-model="objeto.data_fim" outlined label="Data fim " type="date" dense clearable
                :rules="requiredField" :loading="loading" />
            </v-col>
            <v-col class="text-end">
              <v-menu eager offset-x left v-model="menu" content-class="menu-content">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class=" align-center mx-8 " color="green" size="30" v-bind="attrs" v-on="on">
                    mdi-printer
                  </v-icon>
                </template>
                <v-container class="container">
                  <relatorioProdutividade :dados="tableData" :data_inicio="objeto.data_inicio"
                    :data_fim="objeto.data_fim"></relatorioProdutividade>
                  <relatorioProdutividadeDetalhado :dados="tableData" :data_inicio="objeto.data_inicio"
                    :data_fim="objeto.data_fim"></relatorioProdutividadeDetalhado>
                  <v-btn color="success" depressed size="small" @click="openModal">
                    <v-icon left>mdi-printer</v-icon> Rel. por Paciente
                  </v-btn>
                </v-container>
              </v-menu>
              <!-- Botão do Relatório por Paciente -->
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="elevation-0 ml-5" @click="openModal" v-bind="attrs" v-on="on" style="background-color: transparent;">
                    <v-icon class=" align-center mx-8 " color="green" size="30">mdi-printer</v-icon>
                  </v-btn>
                </template>
                <span>Relatório por Paciente</span>
              </v-tooltip> -->
              <v-btn color="red" text class="mr-1" elevation="0" @click="clearFilters">limpar</v-btn>
              <v-btn color="gree" class="mr-1" elevation="0" @click="atualizar"> <v-icon left>mdi-reload </v-icon>Atualizar</v-btn>
              <v-btn color="success" elevation="0" :loading="loading" @click="applyFilters">Pesquisar</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="tableData.length > 0" :loading="loading">
      <v-col>
        <v-tabs v-model="activeTab">
          <v-tab>Resumo</v-tab>
          <v-tab>Detalhes</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item>
            <Resumo :table-data="tableData" class="custom-tab-item" />
          </v-tab-item>
          <v-tab-item>
            <Detalhes :table-data="tableData" class="custom-tab-item" :data_inicio="objeto.data_inicio"
                    :data_fim="objeto.data_fim" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <!-- Modal de Relatório por Paciente -->
    <RelatorioProdutividadeDetalhadoPaciente
      v-if="showModal"
      :dados="tableData"
      :data_inicio="objeto.data_inicio"
      :data_fim="objeto.data_fim"
      :showModal.sync="showModal"
    />
  </v-container>
</template>
  
<script>
import api from "@/http";
import UtilsFunc from "../../service/utilsFunc";
import '../../assets/css/styles.css'

export default {
  name: "Atendimentos",
  components: {
    Detalhes: () => import('./Faturamento'),
    Resumo: () => import('./Produtividade'),
    RelatorioProdutividade: () => import('../relatorios/Produtividade.vue'),
    RelatorioProdutividadeDetalhado: () => import('../relatorios/ProdutividadeDetalhada.vue'),
    RelatorioProdutividadeDetalhadoPaciente: () => import('../relatorios/ProdutividadeDetalhadaPaciente.vue'),
  },
  data: () => UtilsFunc.withCRUDUtils({
    activeTab: false,
    loading: false,
    items: [],
    menu: false,
    prestador: "",
    prestadores: [],
    especialidade: [],
    objeto: {
      data_inicio: '', // Definindo data de início como vazio
      data_fim: '', // Definindo data de término como vazio
    },
    activeFilters: [],
    dataFetched: false,
    showFilters: true,
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    showModal: false,
  }),
  methods: {
    async getProfissional() {
      const LOADING_NAME = "get:Profissional";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api(`contas/app-usuarios-id-nome/`);
        this.prestadores = data;
      } catch (error) {
        // this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getEspecialidade() {
      const LOADING_NAME = "get:Especialidade";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api(`contas/especialidades/`);
        this.especialidade = data
        // console.log(`Especialidade`, this.especialidade)
      } catch (error) {
        // this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getProducao(params) {
      const LOADING_NAME = "get:Producao";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api(`atendimentos/produtividade-final-colaborador/?${params}`);
        this.tableData = data;
        // console.log(this.tableData)
      } catch (error) {
        // this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
        this.dataFetched = true;
      }
    },
    clearFilters() {
      this.objeto = {};
    },
    async applyFilters() {
      this.loading = true;
      if (!this.objeto.data_inicio || !this.objeto.data_fim) {
        this.$toast.error('Por favor, preencha as datas de Início e Fim do período para prosseguir com a pesquisa.');
        this.loading = false;
        return;
      }
      if (this.isFilterApplied()) {
        const filters = { ...this.objeto };
        let dataFim = new Date(this.objeto.data_fim);
        dataFim.setDate(dataFim.getDate() + 1);
        let dataFimStr = dataFim.toISOString().split('T')[0];
        let params = '';
        Object.keys(filters).forEach(k => {
          if (filters[k]) {
            params += `&${k}=${k === 'data_fim' ? dataFimStr : filters[k]}`;
          }
        });
        try {
          await this.getProducao(params);
          // await this.getEspecialidade(params);
        } catch (error) {
          this.$toast.error('Ocorreu um erro ao buscar os dados. Por favor, tente novamente mais tarde.');
        } finally {
          this.loading = false;
        }
      } else {
        this.$toast.error('Por favor, preencha pelo menos um filtro para pesquisar.');
        this.loading = false;
      }
    },
    isFilterApplied() {
      return Object.keys(this.objeto).some(key => this.objeto[key] != null && this.objeto[key] !== '');
    },
    resetFilters() {
      // Resetar objeto de filtros para os valores iniciais
      this.objeto = {
        data_inicio: '', // valor inicial
        data_fim: '', // valor inicial
        // outros filtros com seus valores iniciais
      };
      
      // Aqui você pode definir os valores iniciais da mesma forma que no `mounted`
      const today = new Date();
      const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      this.objeto.data_inicio = firstDayOfLastMonth.toISOString().split('T')[0];

      const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      this.objeto.data_fim = lastDayOfLastMonth.toISOString().split('T')[0];
    },
    atualizar() {
      this.resetFilters();
      this.getProfissional();
      this.getEspecialidade();
      this.applyFilters();
    },
    openModal() {
      // console.log("Abrindo modal do relatório por paciente...");
      this.showModal = true;
    },
  },
  mounted() {
    this.getProfissional();
    this.getEspecialidade();

    // Definindo a data de início como o primeiro dia do mês anterior
    const today = new Date();
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    this.objeto.data_inicio = firstDayOfLastMonth.toISOString().split('T')[0];

    // Definindo a data de término como o último dia do mês anterior
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    this.objeto.data_fim = lastDayOfLastMonth.toISOString().split('T')[0];

    this.applyFilters();
  },
};
</script>
  
<style scoped>
.custom-alert {
  background-color: white !important;
  color: red !important;
}

.custom-tab-item {
  height: 500px;
  width: 100%;
}

.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
}

.table-container {
  max-height: 500px; /* Define a altura máxima para o contêiner da tabela */
  overflow-y: auto;  /* Permite a rolagem vertical */
}

.fixed-header-table thead th {
  background-color: white; /* Cor de fundo do cabeçalho para que ele permaneça visível */
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>

]